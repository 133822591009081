import { useLocation } from '@reach/router'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Loader from '../../assets/images/blog/loader.gif'
import SectionContainer from '../../components/molecules/SectionContainer'
import { NodeTypes } from '../../helpers/constants'
import { formatSrcToGatsbyImage } from '../../helpers/formatSrcToGatsbyImage'
import Seo from '../../helpers/seo'
import useContentfulJobs from '../../hooks/useContentfulJobs'
import Layout from '../layout'
import JobContent from './content'
import './styles.scss'

const linkHTMLElement = (url, text) => {
  return `<a href='${url}' target='_blank'>${text}</a>`
}

const getHTMLElements = (item) => {
  const isTextSpecial = item.content.length > 1 && item.nodeType === NodeTypes.PARAGRAPH
  const isOrderedList = item.nodeType === NodeTypes.ORDERED_LIST
  const isUnorderedList = item.nodeType === NodeTypes.UNORDERED_LIST
  const isImage = item.nodeType === NodeTypes.EMBEDDED_ASSET_BLOCK

  if (isTextSpecial) {
    const valueWithMarks = item.content.map((t) => {
      const textHasHyperlink = t.nodeType === NodeTypes.HYPERLINK
      if (textHasHyperlink) {
        return linkHTMLElement(t.data?.uri, t.content[0].value)
      }

      const textHasBold = t.marks && t.marks.length && t.marks[0].type === 'bold'
      if (textHasBold) {
        return `<strong>${t.value}</strong>`
      }
      return t.value
    })

    return {
      componentContent: { value: valueWithMarks.join('') },
      type: item.nodeType,
    }
  }

  if (isUnorderedList) {
    const items = item.content.map(({ content }) => content[0])
    const listItems = items.map(({ content }) => {
      const link = content.find((i) => i.nodeType === 'hyperlink')

      if (link) {
        return linkHTMLElement(link.data?.uri, link.content[0].value)
      }

      return content[0].value
    })

    return { componentContent: { value: listItems }, type: item.nodeType }
  }

  if (isOrderedList) {
    const itemsOfList = item.content.map(({ content }) => content[0])
    const textsList = itemsOfList.map(({ content }) => {
      const link = content.find((i) => i.nodeType === 'hyperlink')

      if (link) {
        return linkHTMLElement(link.data?.uri, link.content[0].value)
      }

      return content[0].value
    })
    return { componentContent: { value: textsList }, type: item.nodeType }
  }

  if (isImage) {
    const { file, title, description } = item.data.target.fields
    const value = { url: file.url, caption: description, title }
    return { componentContent: { value }, type: item.nodeType }
  }

  if (item.nodeType === 'paragraph' && item.content[0].length === 0) {
    return null
  } else {
    return { componentContent: item.content[0], type: item.nodeType }
  }
}

const Job = () => {
  const location = useLocation()
  const jobs = useContentfulJobs()

  const currentPathname = location.pathname.replace('/jobs/', '').replace('/', '')
  const currentJob = jobs.find(
    (job) => job.path.replace('/jobs/', '').replace('/jobs/', '') === currentPathname,
  )

  const benefitsHtmlElements = currentJob?.benefits?.content?.map((item) =>
    getHTMLElements(item),
  )
  const hardSkillsHtmlElements = currentJob?.hardSkills?.content?.map((item) =>
    getHTMLElements(item),
  )
  const skillsHtmlElements = currentJob?.skills?.content?.map((item) =>
    getHTMLElements(item),
  )
  const differentialsHtmlElements = currentJob?.differentials?.content?.map((item) =>
    getHTMLElements(item),
  )

  if (!currentJob) {
    return (
      <div className="loader">
        <img className="loader__image" src={Loader} alt="loader" />
      </div>
    )
  }

  return (
    <>
      <Seo title={currentJob?.title} />

      <Layout page="Jobs">
        <GatsbyImage
          className="job__banner"
          image={formatSrcToGatsbyImage(currentJob?.banner?.file?.url)}
          alt={currentJob?.banner?.file?.title}
        />
        <SectionContainer>
          <JobContent
            aboutCompany={currentJob?.aboutCompany}
            benefitsHtmlElements={benefitsHtmlElements}
            skillsHtmlElements={skillsHtmlElements}
            hardSkillsHtmlElements={hardSkillsHtmlElements}
            differentialsHtmlElements={differentialsHtmlElements}
            description={currentJob?.jobDescription}
            operatingModel={currentJob?.operatingModel}
            contractModel={currentJob?.contractModel}
            salary={currentJob?.salary}
            title={currentJob?.title}
            location={currentJob?.location}
            vacancyType={currentJob?.vacancyType}
            url={currentJob?.url}
          />
        </SectionContainer>
      </Layout>
    </>
  )
}

export default Job
