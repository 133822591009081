import { useState, useEffect } from 'react'
import { createClient } from 'contentful'

const useContentfulJobs = () => {
  const [jobs, setJobs] = useState([])

  useEffect(() => {
    const contentfulClient = createClient({
      space: process.env.CONTENTFUL_CLIENT_SPACE,
      environment: process.env.CONTENTFUL_CLIENT_ENV,
      accessToken: process.env.CONTENTFUL_CLIENT_ACCESS_TOKEN,
    })

    const regexFindAccentuation = /[\u0300-\u036f]+/g
    const regexFindAllSpaces = /[\s]/g

    contentfulClient
      .getEntries({ content_type: 'jobVacancy' })
      .then(({ items: conentfulJobs }) => {
        const jobList = conentfulJobs.map(({ fields, sys }) => {
          const data = {
            id: sys.id,
            title: fields.jobTitle,
            banner: fields.banner.fields,
            url: fields.jobUrl,
            aboutCompany: fields.aboutCompany,
            benefits: fields.benefits,
            location: fields.location,
            operatingModel: fields.operatingModel,
            contractModel: fields.contractModel,
            skills: fields.skills,
            hardSkills: fields.hardSkills,
            salary: fields.salary,
            jobDescription: fields.jobDescription,
            differentials: fields.differentials,
            sector: fields.sector,
            vacancyType: fields.vacancyType,
            path: `/jobs/${fields.jobTitle
              .trim()
              .replace(regexFindAllSpaces, '-')
              .normalize('NFD')
              .replace(regexFindAccentuation, '')
              .replace(/\//g, '-')
              .toLowerCase()}`,
          }

          return data
        })

        setJobs(jobList)
      })
      .catch((err) => err)
  }, [])

  return jobs
}

export default useContentfulJobs
